var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"item_menu",class:[
        _vm.OptionActive
          ? "vs-sidebar--item vs-sidebar-item-active router-link-exact-active router-link-active"
          : 'vs-sidebar--item',
        _vm.rootOption ? '' : 'ml-5 font-weight-light' ],attrs:{"ripple":{ center: true, class: 'primary--text' }},on:{"click":function($event){return _vm.OptionClick()}}},'v-list-item',_vm.item.text.length >= 20 ? attrs : undefined,false),_vm.item.text.length >= 20 ? on  : undefined),[(_vm.item.icon !== '')?_c('v-list-item-icon',[_c('v-icon',{staticStyle:{"font-size":"1.5rem"}},[_vm._v(_vm._s(_vm.item.icon))])],1):_c('v-list-item-icon',[_c('i',{class:_vm.item.icon_fntaws,style:(_vm.stilo)})]),_c('v-list-item-title',[_vm._v(_vm._s(_vm.item.text))]),_c('v-list-item-content')],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.item.text))])])}
var staticRenderFns = []

export { render, staticRenderFns }